import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import config from '../config/config.json';
import 'hammerjs';

if (config.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
	.then(() => {
		if ('serviceWorker' in navigator && config.production) {
			navigator.serviceWorker.register('./ngsw-worker.js');
		}
	})
	.catch(err => console.error(err));
