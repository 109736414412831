import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RallyService} from "./rally.service";

@Injectable()
export class RallyInterceptor implements HttpInterceptor {

	constructor(private rallyService: RallyService) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		console.log('INSIDE:: RallyInterceptor', request.url);
		if (request.url.includes(this.rallyService.getPrefixUrl())) {
			console.log('INSIDE:: RallyInterceptor - conditional');
			const headers = this.rallyService.getHeaders();
			const rallyRequest = request.clone({
				headers, //TODO: Merge the headers instead of overwriting
			});
			console.log('rallyRequest::header => ', rallyRequest.headers);

			return next.handle(rallyRequest);
		}

		return next.handle(request);
	}
}
