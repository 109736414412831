import {
	ErrorHandler,
	Injectable
} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
	Message,
	MessageService
} from 'primeng/api';
import {
	EMPTY,
	Observable, throwError
} from 'rxjs';

/** Error handler class to assist with cleaning up repeated and consistent HTTP error handling */
@Injectable({
	providedIn: 'root'
})
export class HttpErrorHandler implements ErrorHandler {

	/**
	 * Creates an instance of the HttpErrorHandler
	 * @param messageService an instance of the PrimeNG MessageService
	 */
	constructor(private readonly messageService: MessageService) {
	}

	/**
	 * takes in the error and errorMessage and uses them to handle the error appropriately.
	 * @param error the HttpErrorResponse object
	 * @param errorMessage optionally, the Message object for the error
	 * @returns an empty Observable
	 */
	handleError(error: HttpErrorResponse, errorMessage?: Message): Observable<never> {
		if (error.error instanceof ErrorEvent) {
			console.error('An error occurred:', error.error.message);
		} else {
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}

		if (errorMessage) {
			this.messageService.add(errorMessage);
		}

		return throwError(errorMessage);
	}
}
