<div id="app-branding">
	<div id="app-logo" routerLink="/">
		<div id="app-icon">
			<img alt="Ford Oval" src="/assets/image/FordOval.png"/>
		</div>
		<div id="app-header-text">
			<h1>EAMS &amp; Rally Alignment Tool</h1>
		</div>
	</div>
</div>
