import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EamsRallyAlignmentToolComponent} from './components/eams-rally-alignment-tool/eams-rally-alignment-tool.component';
import {AlignmentToolTreeNodeResolver} from './resolves/alignment-tool-tree-node.resolver';
import {DocumentationComponent} from "./components/documentation/documentation.component";
import {SupportComponent} from "./components/support/support.component";
import {AppAuthorizationGuard} from "../oauth/guards/app-authorization.guard";

const routes: Routes = [
	{
		path: 'home',
		component: EamsRallyAlignmentToolComponent,
		resolve: {
			treeNodes: AlignmentToolTreeNodeResolver
		},
		canActivate: [AppAuthorizationGuard]
	},
	{
		path: 'documentation',
		component: DocumentationComponent,
		canActivate: [AppAuthorizationGuard]
	},
	{
		path: 'support',
		component: SupportComponent,
		canActivate: [AppAuthorizationGuard]
	},
	{
		path: '',
		redirectTo: 'home',
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class ComparisonRoutingModule {}
