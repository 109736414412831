import {Injectable} from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ItDataHubService} from "./it-data-hub.service";

@Injectable()
export class ItDataHubInterceptor implements HttpInterceptor {

	constructor(private itDataHubService: ItDataHubService) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		console.log('INSIDE:: ItDataHubInterceptor', request.url);
		if (request.url.includes(this.itDataHubService.getPrefixUrl())) {
			console.log('INSIDE:: ItDataHubInterceptor - conditional');
			const headers = this.itDataHubService.getHeaders();
			const itDataHubRequest = request.clone({
				headers, //TODO: Merge the headers instead of overwriting
			});
			console.log('itDataHubRequest::header => ', itDataHubRequest.headers);
			return next.handle(itDataHubRequest);
		}

		return next.handle(request);
	}
}
