<p-treeTable #pdoComparison [value]="pdoTrees" styleClass="p-treetable-sm">
	<ng-template pTemplate="caption">
		<div class="grid grid-nogutter">
			<div class="col-12 grid-nogutter wc-text-right">
				<p-button (click)="expandAll()"
						  i18n-label
						  styleClass="mx-1"
						  icon="fas fa-expand-alt"
						  iconPos="left"
						  id="expand-all"
						  label="Expand All"
						  type="button">
				</p-button>
				<p-button (click)="collapseAll()"
						  i18n-label
						  icon="fas fa-compress-alt"
						  iconPos="left"
						  id="collapse-all"
						  label="Collapse All"
						  type="button">
				</p-button>
			</div>
		</div>
	</ng-template>
	<ng-template pTemplate="header">
		<tr class="grid">
			<th class="col-5">EAMS PDO Hierarchy</th>
			<th class="col-2"></th>
			<th class="col-5">Rally Project (Team) Hierarchy</th>
		</tr>
		<tr class="grid">
			<th class="col-4 no-right-border">EAMS Name
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<input pInputText type="text" placeholder="Search" (input)="pdoComparison.filter($event.target.value,'eamsName', 'contains')">
				</span>
			</th>
			<th class="col-1 no-left-border wc-text-center has-legend">
				<span>
					Children
				</span>
				<p-button  #infoChildren class="info-children"	 icon="pi pi-info-circle" (click)="childrenInfo.toggle($event)"></p-button>
				<p-overlayPanel id="infoChildren" #childrenInfo [appendTo]="infoChildren" [dismissable] = "true" [showCloseIcon] = "true" styleClass="childrenPanel">
					<ng-template pTemplate>
						<p-tag styleClass="mx-1 text-base p-tag-eams-count-of-teams">EAMS-Count of Teams</p-tag>
						<p-tag styleClass="mx-1 text-base p-tag-eams-children">EAMS-Children</p-tag>
					</ng-template>
				</p-overlayPanel>
			</th>
			<th class="col-2 wc-text-center has-legend">
				<span>Differences</span>
				<p-button #infoDiff class = "info-button" icon="pi pi-info-circle" (click)="differencesInfo.toggle($event)"></p-button>
				<p-overlayPanel id="infoDifferences" #differencesInfo [appendTo]="infoDiff" [dismissable] = "true" [showCloseIcon] = "true" styleClass="differencesPanel">
					<ng-template pTemplate>
						<p-tag styleClass="mx-1 text-base p-tag-no-diff">No Differences</p-tag>
						<p-tag styleClass="mx-1 text-base p-tag-some-diff">Either the Names OR the Number of Children are Different</p-tag>
						<p-tag styleClass="mx-1 text-base p-tag-both-diff">Both the Names AND the Number of Children are Different</p-tag>
					</ng-template>
				</p-overlayPanel>
			</th>
			<th class="col-4 no-right-border">Rally Name
				<span class="p-input-icon-left">
					<i class="pi pi-search"></i>
					<input pInputText type="text" placeholder="Search" (input)="pdoComparison.filter($event.target.value,'rallyName', 'contains')">
			    </span>
			</th>
			<th class="col-1 no-left-border wc-text-center">
				<div class="grid align-items-center"><span class="col-12">Children</span></div>
			</th>
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
		<tr class="grid {{rowData.level}}">
			<td class="col-4">
				<p-treeTableToggler [rowNode]="rowNode" *ngIf="!rowData.eamsLeaf" ></p-treeTableToggler>
				<span class="p-treetable-no-toggler p-link p-ripple" *ngIf="rowData.eamsLeaf"><i class="fas fa-ellipsis-v"></i></span>
				<span class="p-treetable-toggler-text">{{rowData.eamsName}}</span>
			</td>
			<td class="col-1 wc-text-center"><span *ngIf="!rowData.eamsName == ''" [ngClass]="{'countOfTeam': rowData.eamsLeaf}" [pTooltip]="rowData.eamsLeaf ? 'Count of Teams' : 'Children'" tooltipPosition="left">{{rowData.eamsChildren}}</span></td>
			<td class="col-2 diff wc-text-center"  [ngClass]="{'no-diff': rowData.diff === 'none', 'some-diff': rowData.diff === 'name' || rowData.diff === 'children' , 'both-diff': rowData.diff === 'both'}">
				<p-button
						class="no-diff-badge"
						disabled="true"
						*ngIf="rowData.diff === 'none'">
					Equal &nbsp;<i class="fas fa-equals" ></i>
				</p-button>
				<p-button
						class="some-diff-badge"
						disabled="true"
						*ngIf="rowData.diff === 'name' || rowData.diff === 'children'">
					Partially Equal &nbsp;<i class="fas almost-equal-to">&asymp;</i>
				</p-button>
				<p *ngIf="rowData.diff === 'name' || rowData.diff === 'children'">Are the Names the same? <i class="fas" [ngClass]="{'fa-times no': rowData.diff === 'name', 'fa-check yes': rowData.diff !== 'name'}"></i></p>
				<p *ngIf="rowData.diff === 'name' || rowData.diff === 'children'">Are the Number of children the same? <i class="fas" [ngClass]="{'fa-times no': rowData.diff === 'children', 'fa-check yes': rowData.diff !== 'children'}"></i></p>
				<p-button
						class="both-diff-badge"
						disabled="true"
						*ngIf="rowData.diff === 'both'">
					Not Equal &nbsp; <i class="fas fa-not-equal" ></i>
				</p-button>

			</td>
			<td class="col-4">
				<p-treeTableToggler [rowNode]="rowNode" *ngIf="!rowData.rallyLeaf"></p-treeTableToggler>
				<span class="p-treetable-no-toggler p-link p-ripple" *ngIf="rowData.rallyLeaf"><i class="fas fa-ellipsis-v"></i></span>
				<span class="p-treetable-toggler-text">{{rowData.rallyName}}</span>
			</td>
			<td class="col-1 wc-text-center"><span *ngIf="!rowData.rallyName == ''" pTooltip="Children">{{rowData.rallyChildren}}</span></td>
		</tr>
	</ng-template>
	<ng-template pTemplate="emptymessage">
		<tr class="grid">
			<td class="col-12 wc-text-center">
				No records found
			</td>
		</tr>
	</ng-template>
</p-treeTable>
