/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {ItDataHubInterceptor} from "../../shared/it-data-hub/it-data-hub.interceptor";
import {RallyInterceptor} from "../../shared/rally/rally.interceptor";
import {HttpErrorInterceptor} from "./http-error.interceptor";
import {HttpRequestInterceptor} from "./http-request.interceptor";

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	{ provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: ItDataHubInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: RallyInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
];
