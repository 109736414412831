import {
	Component,
	OnInit
} from '@angular/core';
import {
	NavigationCancel,
	NavigationEnd, NavigationError, NavigationStart,
	Router
} from '@angular/router';
import {filter} from 'rxjs/operators';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	/** string used to hold the url for the skipToMain link */
	skipToMain: string;
	loading: boolean;

	/** constructor for setting up DI in this component */
	constructor(private readonly router: Router) {
		this.router.events.subscribe(ev => {
			if (ev instanceof NavigationStart) {
				this.loading = true;
			}
			if (ev instanceof NavigationEnd || ev instanceof NavigationCancel || ev instanceof NavigationError) {
				this.loading = false;
			}
		});
	}

	/** this class requires this method to implement the OnInit interface */
	ngOnInit(): void {
		this.router.events.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setSkipLinkUrl(event.urlAfterRedirects);
			});
	}

	/**
	 * setSkipLinkUrl takes in a url string and processes whether
	 * the skipToMain link should be updated to use the new value
	 * @param currentUrl the new url to refer to
	 */
	private setSkipLinkUrl(currentUrl: string): void {
		if (!currentUrl.endsWith('#app-content')) {
			this.skipToMain = `${currentUrl}#app-content`;
		}
	}
}
