import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlignmentToolDataService} from '../../services/alignment-tool-data.service';
import {TreeNode} from 'primeng/api';

/** Component that has no functionality other than plain html text in the template */
@Component({
	selector: 'eams-rally-alignment-tool',
	templateUrl: './eams-rally-alignment-tool.component.html',
	styleUrls: ['./eams-rally-alignment-tool.component.scss']
})
export class EamsRallyAlignmentToolComponent implements OnInit {
	pdoTrees: TreeNode[];

	/** empty constructor */
	constructor(private route: ActivatedRoute, private router: Router, private alignmentToolDataService: AlignmentToolDataService) {
		this.pdoTrees = this.route.snapshot.data['treeNodes'] || [];
	}

	/** used for functionality that doesn't belong in the constructor but still
	 * needs to be run at the beginning of the lifecycle of the component.
	 */
	ngOnInit(): void {
	}

	expandAll(): void {
		this.pdoTrees.forEach(node => {
			this.expandCollapseRecursive(node, true);
		});
		this.pdoTrees = [...this.pdoTrees];
	}

	collapseAll(): void {
		this.pdoTrees.forEach(node => {
			this.expandCollapseRecursive(node, false);
		});
		this.pdoTrees = [...this.pdoTrees];
	}

	private expandCollapseRecursive(node: TreeNode, isExpand: boolean): void {
		node.expanded = isExpand;
		if (node.children) {
			node.children.forEach(childNode => {
				this.expandCollapseRecursive(childNode, isExpand);
			});
		}
	}
}
