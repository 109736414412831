import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AlignmentToolDataService} from "../services/alignment-tool-data.service";
import {TreeNode} from "primeng/api";

@Injectable({
	providedIn: 'root'
})
export class AlignmentToolTreeNodeResolver implements Resolve<TreeNode<any> | TreeNode<any>[]> {
	/**
	 *
	 * @param alignmentToolDataService
	 */
	constructor(private alignmentToolDataService: AlignmentToolDataService) {}

	/**
	 *
	 * @param route
	 * @param state
	 */
	resolve(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Observable<TreeNode<any> | TreeNode<any>[]> {
		return this.alignmentToolDataService.getTreeNodes();
	}
}
