import packageJson from "../../../../../package.json";
import {PDOProduct} from "./eams-apm.model";
import {Observable, of} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import config from '../../../../../config/config.json';
import {catchError, tap} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ItDataHubService {
	constructor(private http: HttpClient) {
	}

	getPrefixUrl() {
		return config.itDataHub.productApiUrl;
	}

	getHeaders(): HttpHeaders {
		return new HttpHeaders({
			// 'user-agent': `${packageJson.name}/${packageJson.version} (https://github.ford.com/Planning-Tools/${packageJson.name})`,
			'X-ITDataHubIntegrationName': packageJson.name,
			'X-ITDataHubIntegrationVendor': 'Ford Motor Company',
			'X-ITDataHubIntegrationVersion': packageJson.version
		});
	}

	getPDOProducts(): Observable<PDOProduct[]> {
		return this.http.get<PDOProduct[]>(this.getPrefixUrl() + 'getpdoproducts')
			.pipe(
				tap((response) => console.log('getPDOProducts::response', response)),
				catchError(() => of([]))
			);
	}
}
