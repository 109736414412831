import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EamsRallyAlignmentToolComponent} from './components/eams-rally-alignment-tool/eams-rally-alignment-tool.component';
import {ComparisonRoutingModule} from './comparison-routing.module';
import {AlignmentToolTreeNodeResolver} from './resolves/alignment-tool-tree-node.resolver';
import {AlignmentToolDataService} from './services/alignment-tool-data.service';
import {ItDataHubService} from '../shared/it-data-hub/it-data-hub.service';
import {RallyService} from '../shared/rally/rally.service';
import {TreeTableModule} from 'primeng/treetable';
import {DocumentationComponent } from './components/documentation/documentation.component';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import { TooltipModule } from "primeng/tooltip";
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputTextModule} from 'primeng/inputtext';
import {TagModule} from 'primeng/tag';
import { SupportComponent } from './components/support/support.component';
import {DialogModule} from 'primeng/dialog';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {PanelModule} from 'primeng/panel';

@NgModule({
	declarations: [
		EamsRallyAlignmentToolComponent,
		DocumentationComponent,
		SupportComponent
	],
	imports: [
		CommonModule,
		ComparisonRoutingModule,
		ButtonModule,
		TreeTableModule,
		TableModule,
		TooltipModule,
		OverlayPanelModule,
		InputTextModule,
		TagModule,
		DialogModule,
		ProgressSpinnerModule,
		PanelModule
	],
	providers: [
		ItDataHubService,
		RallyService,
		AlignmentToolDataService,
		AlignmentToolTreeNodeResolver
	]
})
export class ComparisonModule {
}
