import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}

}
