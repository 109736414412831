import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {OauthCallbackComponent, OauthTokenGuard} from "@wame/ngx-adfs";

const routes: Routes = [
	{
		path: 'oauth',
		component: OauthCallbackComponent,
		canActivate: [OauthTokenGuard]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class OauthRoutingModule {}
