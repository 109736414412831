import {Component} from '@angular/core';
import config from '../../../../../../config/config.json';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
	environment: string;
	constructor() {
		this.environment = (config.environment === 'prod') ? 'Production' : ((config.environment === 'qa') ? 'Staging' : ((config.environment === 'dev') ? 'Development' : 'Local'));
	}
}
