<header id="app-header">
	<a [href]="skipToMain" class="skip-link">Skip to Content</a>
	<app-nav></app-nav>
</header>
<main id="app-content">
	<p-toast position="top" [breakpoints]="{'1920px': {width: '95%', right: '0', left: '0', margin: '0 auto'}}"></p-toast>
	<!--<p-dialog [visible]="true" [showHeader]="false" [resizable]="false" [modal]="true" [focusOnShow]="false" >-->
	<!--	<div style="width: 900px; height: 500px; padding-top: 20%;">-->
	<!--		<p-progressSpinner></p-progressSpinner>-->
	<!--	</div>-->
	<!--</p-dialog>-->
	<div class="progress-spinner" *ngIf="loading">
		<p-progressSpinner></p-progressSpinner>
	</div>
	<router-outlet></router-outlet>
</main>
<footer id="footer">
	<app-footer></app-footer>
</footer>
