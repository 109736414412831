import {Injectable} from '@angular/core';
import {forkJoin, Observable, of,} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Project, RallyResult} from "./rally-objects.model";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import packageJson from "../../../../../package.json";
import config from '../../../../../config/config.json';


@Injectable({ providedIn: 'root' })
export class RallyService {
	private workspaces : any = {
		Ford: 34494663231,
		QaAndTraining: 34494676139
	};

	constructor(private http: HttpClient) {
	}

	getPrefixUrl() {
		return config.rally.wsApiUrl;
	}

	getHeaders(): HttpHeaders {
		return new HttpHeaders({
			'Authorization': `Bearer ${config.rally.apiKey}`,
			'x-api-key': config.rally.apiKey,
			// 'user-agent': `${packageJson.name}/${packageJson.version} (https://github.ford.com/Planning-Tools/${packageJson.name})`,
			'X-RallyIntegrationName': packageJson.name,
			'X-RallyIntegrationVendor': 'Ford Motor Company',
			'X-RallyIntegrationVersion': packageJson.version,
			'X-RallyIntegrationOS': 'Cloud Foundry Linux-based filesystem - Ubuntu Bionic 18.04 LTS',
			// 'X-RallyIntegrationPlatform': `Nginx v${packageJson.dependencies["@angular/core"]}`,
			'X-RallyIntegrationLibrary': `Angular v${packageJson.dependencies["@angular/core"]}`,
		});
	}

	loadPage(url: string, options: any, pagesize: number, start: number): Observable<RallyResult> {
		options.params.start = start;
		options.params.pagesize = pagesize;
		options.responseType = 'json';
		console.log(`${options.method}  ${url}: pagesize: ${pagesize} start: ${start}`);

		// @ts-ignore
		return this.http.get<RallyResult>(this.getPrefixUrl() + url, options)
			.pipe(
				tap((response) => console.log('loadPage::response', response)),
				catchError(() => of({ QueryResult: { Results: [], TotalResultCount: 0 } } as RallyResult))
			);
	}

	loadPages(url: string, options: any, pagesize: number, usePost?: boolean): Observable<Project[]>  {
		options.method = 'GET';

		if (usePost) {
			options.method = 'POST';
			options.form = {
				_method: 'GET',
				query: options.params.query
			};
			options.timeout = 300000; //300000 ms = 5 min
			options.retry = 2;

			delete options.params.query;
		}

		let start = 1;
		let total = 0;

		return this.loadPage(url, options, pagesize, start)
			.pipe(
				tap((response) => console.log('loadPages::response', response)),
				switchMap(response => {
					console.log('switchMap::response', response);
					const results = response.QueryResult.Results;
					const remainingPageCalls = [];
					remainingPageCalls.push(of(results)); //Add the results of the first call

					total = response.QueryResult.TotalResultCount;
					start += pagesize;

					while (start < total) {
						remainingPageCalls.push(
							this.loadPage(url, options, pagesize, start)
								.pipe(
									map(response => {
										return response.QueryResult.Results;
									}),
									catchError(() => of([]))
								)
						);

						start += pagesize;
					}

					return forkJoin<Project[]>(remainingPageCalls)
						.pipe(
							tap((results) => {
								console.log('forkJoin::results', results)
							}),
							map(results => {
								return results.reduce((previousValue, currentValue) => previousValue.concat(currentValue), []).sort();
							}),
							tap((results) => {
								console.log('reduce::results', results)
							}),
							catchError(() => of([]))
						);
				}),
				catchError(() => of([]))
			);
	}

	getProjects(): Observable<Project[]> {
		return this.loadPages('project', {
			params: {
				workspace: `/workspace/${this.workspaces.Ford}`,
				fetch: ['Name', 'ObjectID', 'Parent', 'Children'].join(','),
				query: '(State = "Open")'
			},
		}, 2000, false)
	}
}
