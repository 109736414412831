import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppComponent} from './app.component';
import {NavComponent} from './modules/core/components/nav/nav.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';
import {AppRoutingModule} from './app-routing.module';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {FooterComponent} from './modules/core/components/footer/footer.component';
import {HeaderComponent} from './modules/core/components/header/header.component';
import {httpInterceptorProviders} from './modules/core/interceptors';
import {CoreModule} from './modules/core/core.module';
import {ComparisonModule} from './modules/comparison/comparison.module';
import {HttpClientModule} from '@angular/common/http';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {OauthModule} from "./modules/oauth/oauth.module";
import { BadgeModule } from "primeng/badge";
import {
	NgxFrfUtilitiesModule,
	NotFoundComponent
} from '@wame/ngx-frf-utilities';


@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		FooterComponent,
		HeaderComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		ButtonModule,
		HttpClientModule,
		RouterModule,
		MenubarModule,
		NgxFrfUtilitiesModule,
		PanelMenuModule,
		SidebarModule,
		ToastModule,
		ProgressSpinnerModule,
		CoreModule,
		ComparisonModule,
		OauthModule,
		AppRoutingModule,
		BadgeModule,
	],
	providers: [
		MessageService,
		httpInterceptorProviders,
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
