import {Observable, of, map} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {TreeNode} from "primeng/api";
import config from '../../../../../config/config.json';
import {catchError, tap} from "rxjs/operators";

export interface TreeNodesResult {
	treeNode: TreeNode[]
}

@Injectable({ providedIn: 'root' })
export class AlignmentToolDataService {
	constructor(private http: HttpClient) {
	}

	getPrefixUrl() {
		return config.planningToolsDataApi.alignmentToolApiUrl;
	}

	getHeaders(): HttpHeaders {
		return new HttpHeaders({
			'x-api-key': config.rally.apiKey
		});
	}

	getTreeNodes() : Observable<TreeNode<any> | TreeNode<any>[]> {
		const options = {
			headers: this.getHeaders()
		}
		return this.http.get<TreeNodesResult>(this.getPrefixUrl() + 'alignmenttool/?nodeType=tree',options)
			.pipe(
				tap((response) =>
					console.log('getTreeNode::response', response)
				),
				map(response => {
					console.log('map :: treeNodes: ', response.treeNode);
					return response.treeNode;
				}),
				catchError(() => of([]))
			)
	}
}
