<p-panel header="Instructions for EAMS Support">
	<ul>
		<li>Go to <a href="https://www.itconnect.ford.com" target="_blank">IT Connect</a></li>
		<li>Application Support Request</li>
		<li>Application Lookup -> EAMS</li>
		<li>Application Environment -> Production</li>
		<li>Request Category -> Data Management</li>
		<li>Request Action -> Data Update</li>
		<li>Select Approvers Type -> EAMS</li>
		<li>Approval Type -> Business Application Owner</li>
		<li>Request Summary -> Enter informational summary</li>
		<li>Detailed Description of the Request -> Enter PDO structure details</li>
		<li>Attach Files: You must attach management approval email for a Product Structure creation or change (e.g. If the requester is requesting a new Product line, the requester should attach evidence of approval from the product line manager to create the new product line.
			Submit Request</li>
	</ul>
</p-panel>
<p-panel header="Instructions for Rally Support">
	<ul>
		<li>Go to <a href="https://www.itconnect.ford.com" target="_blank">IT Connect</a></li>
		<li>Application Support Request</li>
		<li>Application Lookup -> 22797-Rally Broadcom(Rally)</li>
		<li>Application Environment -> Production</li>
		<li>Any appropriate Request Category can be selected, but for fastest service select How To for all types of requests</li>
		<li>Select Submit Request for Request Action</li>
		<li>Enter an appropriate Request Summary and Detailed Description of the Request. Additional information on what is required for different types of requests can be found below.</li>
	</ul>
</p-panel>

