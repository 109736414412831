import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, retry, tap} from "rxjs/operators";
import {HttpErrorHandler} from "../error-handlers/http-error.handler";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(private errorHandler: HttpErrorHandler) {
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request)
			.pipe(
				retry(1),
				catchError((error) => {
					console.log('intercept::catchError::error', error);
					const url = new URL(error.url);
					return this.errorHandler.handleError(error, {
						severity: 'error',
						summary: `Failed to retrieve: ${url.pathname}`,
						sticky: true
					});
				}),
			);
	}
}
