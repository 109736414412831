import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppAuthorizationGuard} from './guards/app-authorization.guard';
import {NgxAdfsModule} from "@wame/ngx-adfs";
import {OauthRoutingModule} from "./oauth-routing.module";
import config from '../../../../config/config.json';

@NgModule({
	imports: [
		CommonModule,
		NgxAdfsModule.forRoot({
			openidUrl: config.adfs.openid.authorizeUrl,
			openidClientId: config.adfs.openid.clientId,
			openidResource: config.adfs.openid.resourceUri,
		}),
		OauthRoutingModule
	],
	declarations: [
	],
	providers: [
		AppAuthorizationGuard,
	]
})
export class OauthModule {}
