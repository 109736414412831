import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import config from '../../../../../config/config.json';
import {AuthenticationGuard} from "@wame/ngx-adfs";

/**
 * This guard is used across all app routes that require live data,
 * determining if the auth token is valid before allowing navigation to occur
 */
@Injectable()
export class AppAuthorizationGuard implements CanActivate {

	/** Creates an instance of the AppAuthorizationGuard
	 *
	 * @param appAuthenticationGuard used to access the WAME ADFS package AuthenticationGuard
	 */
	constructor(private appAuthenticationGuard: AuthenticationGuard) {
	}

	/** the actual guard for routing. Uses the current token information to determine if the user needs to go through the login flow again
	 *
	 * @param route the snapshot of the current ActivatedRoute
	 * @param state the snapshot of the current RouterState
	 * @returns whether route can be activated or not
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (config.options.inmem) {
			return true;
		}

		return this.appAuthenticationGuard.canActivate(route, state);
	}
}
