<p-button #releasesButton label="Releases" (click)="op.toggle($event)" styleClass="p-overlay-button"></p-button>
<p-overlayPanel #op id="releasesPanel" class="releases-panel" [appendTo]="releasesButton" [showCloseIcon]="true" [style]="{width: '450px'}" styleClass="releasesPanel">
	<ng-template pTemplate>
		<ul>
			<li>
				<strong>v2.2.0</strong>
				<ul>
					<li>Differences Column - Home
						<ul>
							<li>Updated Differences column cells to be consistent with the badges in Legend</li>
						</ul>
					</li>
					<li>Documentation
						<ul>
							<li>Updates section is modified to an Overlay Panel called "Releases" on top right of the Documentation page.</li>
							<li>Converted headings and paragraphs in Documentation page to panel headers and panel content respectively.</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>
				<strong>v2.1.0</strong>
				<ul>
					<li>Smart Comparison
						<ul>
							<li>Legend is updated so that the details are shown in overlay panels that are displayed when clicked on information icons.</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>
				<strong>v2.0.0</strong>
				<ul>
					<li>EAMS PDO Hierarchy
						<ul>
							<li>Maps ALL PDOs listed in the EAMS PDO Hierarchy. No longer maps a subset of PDOs.</li>
						</ul>
					</li>
					<li>Smart Comparison
						<ul>
							<li>Loops through the rally subtree to find an exact match for an EAMS node</li>
							<li>If no exact match is found, loops through the tree again and searches for a fuzzy match</li>
						</ul>
					</li>
					<li>Count of Teams
						<ul>
							<li>Shows count of teams value in Children column of EAMS PDO hierarchy.</li>
						</ul>
					</li>
				</ul>
			</li>
			<li>
				<strong>v1.2.0</strong>
				<ul>
					<li>Smart Comparison
						<ul>
							<li>Ignores (re-maps) the "dummy" Product Group levels</li>
							<li>Ignores (re-maps) the "dummy" Product Line levels</li>
							<li>Re-maps the empty Product Lines levels</li>
						</ul>
					</li>
				</ul>
			</li>
		</ul>
	</ng-template>
</p-overlayPanel>

<p-panel header="Purpose of the tool" class="purpose-panel">
	<p>The EAMS PDO Hierarchy is the "Source of Truth" for our hierarchy creation in Rally. Considering that there are some
		outdated projects, and the structure of Rally is also constantly changing, we need a comparison tool to compare the
		differences in Rally and EAMS so that the Rally Project Hierarchy can be reorganized at any time to match EAMS
		Hierarchy.</p>
</p-panel>
<p-panel header="Data Source">
	<p>The EAMS PDO Hierarchy data is extracted from the IT DataHub Product API. Any changes made to the data in EAMS might take 24 hours to reflect in the tool. </p>
	<p>The Rally Project (Team) Hierarchy data is extracted from the Rally Web Services API. Any changes made to the data in Rally should reflect immediately in the tool.</p>
</p-panel>
<p-panel header="Assumptions">
	<ul>
		<li>Data from EAMS will be considered the "Source of Truth". Meaning the comparisons take the EAMS data as correct and the Rally data needs to be updated to reflect what's in eams.</li>
		<li>A project in Rally should have a corresponding entry in EAMS.</li>
		<li>All Rally Projects labeled "Non - PDO" in the name will be excluded from the interface and the comparison along with their child projects.</li>
		<li>An EAMS record does not need to have a project in Rally, however, the tool will flag this scenario as "Red" as the data doesn't pass the Smart Comparison.</li>
		<li>The Ford Rally Project Hierarchy is at most 8 levels deep. The EAMS Hierarchy is 5 levels deep. Any Rally Project created after the 5th level will be out of alignment, unless the EAMS Count of Teams on the 5th level matches the number of children on the 5th level in the Rally Project Hierarchy.</li>
		<li>If the EAMS node has a Count of Teams equal to 1, the associated Rally node can have 0 or 1 child Project. Because of this, the nodes can be out of alignment.</li>
		<li>Smart Comparison
			<ul>
				<li>The comparison tool compares the <strong>Children Count</strong> of each EAMS node & Rally Project node.</li>
				<li>The comparison tool compares the <strong>Names</strong> of each EAMS node and Rally Project node with the following special characters extracted !@#$%^&*()_+-=[] {{ '{' }}};':"\|,.<>/? and tests if...
					<ul>
						<li>The EAMS name and Rally name are exactly equal</li>
						<li>The EAMS name is found within the Rally Name OR The Rally Name is found within the EAMS Name</li>
						<li>The abbreviation/acronym of the EAMS name is exactly equal to the abbreviation/acronym of the Rally name </li>
					</ul>
				</li>
				<li>Count of Teams is only for the leaf(Product) nodes in EAMS hierarchy.</li>
			</ul>
		</li>
	</ul>
</p-panel>
<p-panel header="Process for EAMS / Rally record updates">
	<ul>
		<li>Any requested changes to Rally need to match what is in EAMS</li>
		<li>If not, change EAMS first, and then re-request the change to Rally</li>
		<li>For Rally Hierarchy Changes, <a href="https://azureford.sharepoint.com/sites/PlanningTools/Rally/Pages/Renaming-and-Deleting-Backlogs.aspx" target="_blank">Check if any Tasktop Integrations exists first</a></li>
		<li>Expedition Leads, as defined by our Transformation Steering Council (TSC), can approve these requests</li>
		<li>Exceptions, if any, should come back to the TSC</li>
		<li>For support, follow the instructions on the <a [routerLink]="['/support']">support page</a></li>
	</ul>
</p-panel>
<p-panel header="Usage of the tool">
	<ul>
		<li>"Expand All" button is used to view the complete parent & respective child hierarchy</li>
		<li>"Collapse All" button is used to view the top level hierarchy in alphabetical order</li>
		<li>The filter for EAMS names & Rally project names columns can filter the respective column</li>
		<li>The toggle near the names will expand & collapse for the entire row & not for just one column</li>
		<li>Click on the information&#9432; icon to see the legend on Children column and Differences column</li>
		<li>
			Difference Column Color Definitions:
			<ul>
				<li class="difference-color">
					<p-panel>
						<ng-template pTemplate="header">
							Green - No differences:
							<p-button
									class="no-diff-badge"
									disabled="true">
								Equal &nbsp;<i class="fas fa-equals" ></i>
							</p-button>
						</ng-template>
						<td class="col-2 diff wc-text-center"  class='no-diff'>
							<p>The EAMS PDO Hierarchy <strong>Name</strong> and it's associated Rally Project (Team) <strong>Name </strong> have the exact same spelling.</p>
							<p><strong>AND</strong></p>
							<p>The EAMS PDO Hierarchy <strong>Children Count</strong> and it's associated Rally Project (Team) <strong>Children Count</strong> have the same value.</p>
							<p><strong>OR</strong></p>
							<p>The EAMS PDO Hierarchy Count of Teams value is <= 1 and it's associated Rally Project (Team) Children Count value is 0.</p>
						</td>
					</p-panel>
				</li>
				<li class="difference-color">
					<p-panel>
						<ng-template pTemplate="header">
							Yellow - Either the Names OR the Number of Children are Different:
							<p-button
									class="some-diff-badge"
									disabled="true">
								Partially Equal &nbsp;<i class="fas almost-equal-to">&asymp;</i>
							</p-button>
						</ng-template>
						<td class="col-2 diff wc-text-center"  class='some-diff'>
							<p><strong>EITHER</strong></p>
							<p>The EAMS PDO Hierarchy <strong>Name</strong> and it's associated Rally Project (Team) <strong>Name </strong> <u>DO NOT</u> have the exact same spelling.</p>
							<p><strong>OR</strong></p>
							<p>The EAMS PDO Hierarchy <strong>Children Count</strong> and it's associated Rally Project (Team) <strong>Children Count </strong> <u>DO NOT</u> have the same value.</p>
						</td>
					</p-panel>

				</li>
				<li class="difference-color">
					<p-panel>
						<ng-template pTemplate="header">
							Red - Both the Names AND the Number of Children are Different:
							<p-button
									class="both-diff-badge"
									disabled="true">
								Not Equal &nbsp; <i class="fas fa-not-equal" ></i>
							</p-button>
						</ng-template>
						<td class="col-2 diff wc-text-center"  class='both-diff'>
							<p>The EAMS PDO Hierarchy <strong>Name</strong> and it's associated Rally Project (Team) <strong>Name </strong> <u>DO NOT</u> have the exact same spelling.</p>
							<p><strong>AND</strong></p>
							<p>The EAMS PDO Hierarchy <strong>Children Count</strong> and it's associated Rally Project (Team) <strong>Children Count </strong> <u>DO NOT</u> have the same value.</p>
						</td>
					</p-panel>
				</li>
				<li class="difference-color">
					<p-panel>
						<ng-template pTemplate="header">
							Blank
						</ng-template>
						<td class="col-2 diff wc-text-center"  class='both-diff'>
							<p>In case the <strong>Count of Teams</strong> of a Rally Project is equal to the <strong>Children count</strong> of it's associated EAMS Product then the corresponding Team node's Differences column is blank.</p>
						</td>
					</p-panel>
				</li>
			</ul>
		</li>
		<li>EAMS Children Column Color Definitions:
			<ul>
				<li class="difference-color">
					<p-panel>
						<ng-template pTemplate="header">
							Blue - EAMS-Count of Teams:
						</ng-template>
						<div class="children-diff">
							<div class="col-8 eams-children-diff">
								<span class="p-treetable-no-toggler p-link p-ripple"><i class="fas fa-ellipsis-v"></i></span>
								<span class="p-treetable-toggler-text">Homologate and Certify Product</span>
							</div>
							<div class="col-4 eams-children-diff"><span class="countOfTeam" pTooltip="Count of Teams" tooltipPosition="left">11</span></div>
						</div>
						<p>The Count of Teams value provided for the Product (leaf node).</p>
					</p-panel>
				</li>
				<li class="difference-color">
					<p-panel>
						<ng-template pTemplate="header">
							White - EAMS-Children:
						</ng-template>

						<div class="children-diff">
							<div class="col-8 eams-children-diff">
								<span class="p-treetable-no-toggler p-link p-ripple"><i class="pi pi-fw pi-chevron-right"></i></span>
								<span class="p-treetable-toggler-text">Env. & Safety Compliance</span>
							</div>
							<div class="col-4 eams-children-diff"><span pTooltip="Children" tooltipPosition="left">5</span></div>
						</div>
						<p>The count of children nodes for parent nodes.</p>
					</p-panel>
				</li>
			</ul>
		</li>
	</ul>
</p-panel>

