<div class="grid justify-content-evenly" id="app-footer">
	<div class="col-12">
		<p id="copyright-footer">
			<span class="far fa-copyright copyright-icon"></span>2021 Ford Motor Company
		</p>
		<p>
			Environment: {{environment}}
		</p>
	</div>
</div>
