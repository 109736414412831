import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

/** This class is an implementation of an HttpInterceptor
 */
export class HttpRequestInterceptor implements HttpInterceptor {
	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		console.log('HttpRequestInterceptor::request.url', request.url);
		if (request.url.includes('oauth')) {
			request = request.clone({
				setHeaders: {
					Authorization: 'Bearer ' + sessionStorage.getItem('encodedAccessToken'),
					'Content-Type': 'application/json',
				}
			});
		}
		return next.handle(request);
	}
}
